import { instance } from './index';

// 회원가입
function registerUser(userData) {
  return instance.post('user/signUp', null, { params: userData });
}

// 로그인
function loginUser(userData) {
  return instance.post('user/signIn', null, { params: userData });
}

// 패스워드 리셋
function passwordReset(userData) {
  return instance.post('user/sendEmail', null, { params: userData });
}

// 인증번호 전송
function sendAuthCode(phn) {
  return instance.post('user/send_auth_code', null, { params: { phn : phn } });
}

// 인증하기
function crtfctAuthCode(userData) {
  return instance.post('user/crtfct_auth_code', null, { params: userData });
}

// 휴대폰번호로 이메일 찾기
function findEmailByPhn(phn) {
  return instance.post('user/findEmailByPhn', null, { params: { phn : phn } });
}

// 임시 사용
function findUserByPhn(phn) {
  return instance.post('user/findUserByPhn', null, { params: { phn : phn } });
}

// 신청하기
function goApply(applyData) {
	return instance.post('alimee/apply', null, { params: applyData });
}

// 인증번호 전송
function sendAuthCodeAlimee(phn) {
	return instance.post('alimee/send_auth_code', null, { params: { phn: phn } });
}

// 인증하기
function crtfctAuthCodeAlimee(userData) {
	return instance.post('alimee/crtfct_auth_code', null, { params: userData });
}

export { registerUser, loginUser, passwordReset, sendAuthCode, crtfctAuthCode, findEmailByPhn, findUserByPhn, goApply, sendAuthCodeAlimee, crtfctAuthCodeAlimee };
