import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store/index';

Vue.use(VueRouter)

const router = new VueRouter({
    mode: "history",
    routes: [
        // 초기 진입 페이지
        { path: '/', redirect: '/login' },

        // 아이알리미
        { path: '/apply', name: 'apply', component: () => import('@/views/apply/Apply.vue'), meta: { title: '신청하기' }, },

        // 설문조사
        // { path: '/survey', name: 'survey', component: () => import('@/views/survey/Survey.vue'), meta: { auth: true, title: '설문조사' }, },

        // 본인인증 페이지
        { path: '/nice', name: 'nice', component: () => import('@/views/Nice.vue'), meta: { title: '누리알리미' }, },
        { path: '/cert', name: 'cert', component: () => import('@/views/member/Cert.vue'), meta: { title: '누리알리미' }, },
        { path: '/certPwSet', name: 'certPwSet', component: () => import('@/views/member/CertPwSet.vue'), meta: { title: '누리알리미' }, },
        { path: '/certFindPw', name: 'certFindPw', component: () => import('@/views/member/CertFindPw.vue'), meta: { title: '누리알리미' }, },

        // 00, 01 : member 폴더
        { path: '/agree', name: 'agree', component: () => import('@/views/member/Agree.vue'), meta: { title: '누리알리미' }, },
        { path: '/findEmail', name: 'findEmail', component: () => import('@/views/member/FindEmail.vue'), meta: { title: '누리알리미' }, },
        { path: '/findEmailResult', name: 'findEmailResult', component: () => import('@/views/member/FindEmailResult.vue'), meta: { title: '누리알리미' }, },
        { path: '/join', name: 'join', component: () => import('@/views/member/Join.vue'), meta: { title: '누리알리미' }, },
        { path: '/login', name: 'login', component: () => import('@/views/member/Login.vue'), meta: { title: '누리알리미' }, },
        { path: '/passwordResetComplete', name: 'passwordResetComplete', component: () => import('@/views/member/PasswordResetComplete.vue'), meta: { title: '누리알리미' }, },
        { path: '/right', name: 'right', component: () => import('@/views/member/Right.vue'), meta: { title: '누리알리미' }, },
        
        // 02 : home 폴더
        { path: '/home', name: 'home', component: () => import('@/views/home/Home.vue'), meta: { auth: true, title: '누리알리미' }, },

        // 04 : entryAndExit 폴더
        { path: '/service', name: 'service', component: () => import('@/views/entryAndExit/Service.vue'), meta: { auth: true, title: '누리알리미' }, },
        { path: '/alarmApplication', name: 'alarmApplication', component: () => import('@/views/entryAndExit/AlarmApplication.vue'), meta: { auth: true, title: '누리알리미' }, },
        { path: '/deviceRegPage', name: 'deviceRegPage', component: () => import('@/views/entryAndExit/DeviceRegPage.vue'), meta: { auth: true, title: '누리알리미' }, },
        { path: '/deviceRegComplete', name: 'deviceRegComplete', component: () => import('@/views/entryAndExit/DeviceRegComplete.vue'), meta: { auth: true, title: '누리알리미' }, },
        { path: '/addChild', name: 'addChild', component: () => import('@/views/entryAndExit/AddChild.vue'), meta: { auth: true, title: '누리알리미' }, },
        { path: '/applicationComplete', name: 'applicationComplete', component: () => import('@/views/entryAndExit/ApplicationComplete.vue'), meta: { auth: true, title: '누리알리미' }, },
        { path: '/notice', name: 'notice', component: () => import('@/views/entryAndExit/Notice.vue'), meta: { auth: true, title: '누리알리미' }, },

        // 07 : setting 폴더
        { path: '/setting', name: 'setting', component: () => import('@/views/setting/Setting.vue'), meta: { auth: true, title: '누리알리미' }, },
        { path: '/myInfo', name: 'myInfo', component: () => import('@/views/setting/MyInfo.vue'), meta: { auth: true, title: '누리알리미' }, },
        { path: '/myInfoModify', name: 'myInfoModify', component: () => import('@/views/setting/MyInfoModify.vue'), meta: { auth: true, title: '누리알리미' }, },
        { path: '/pwSet', name: 'pwSet', component: () => import('@/views/setting/PwSet.vue'), meta: { auth: true, title: '누리알리미' }, },
        { path: '/alarm', name: 'alarm', component: () => import('@/views/setting/Alarm.vue'), meta: { auth: true, title: '누리알리미' }, },
        { path: '/alarmSet', name: 'alarmSet', component: () => import('@/views/setting/AlarmSet.vue'), meta: { auth: true, title: '누리알리미' }, },
        { path: '/noticeList', name: 'noticeList', component: () => import('@/views/setting/NoticeList.vue'), meta: { auth: true, title: '누리알리미' }, },
        { path: '/noticeView', name: 'noticeView', component: () => import('@/views/setting/NoticeView.vue'), meta: { auth: true, title: '누리알리미' }, },
        { path: '/complaint', name: 'complaint', component: () => import('@/views/setting/Complaint.vue'), meta: { auth: true, title: '누리알리미' }, },
        { path: '/complaintList', name: 'complaintList', component: () => import('@/views/setting/ComplaintList.vue'), meta: { auth: true, title: '누리알리미' }, },
        { path: '/withdrawal', name: 'withdrawal', component: () => import('@/views/setting/Withdrawal.vue'), meta: { auth: true, title: '누리알리미' }, },
        { path: '/customerCenter', name: 'customerCenter', component: () => import('@/views/setting/CustomerCenter.vue'), meta: { auth: true, title: '누리알리미' }, },

        // 08 : family 폴더
        { path: '/familyList', name: 'familyList', component: () => import('@/views/family/FamilyList.vue'), meta: { auth: true, title: '누리알리미' }, },
        { path: '/familyDetail', name: 'familyDetail', component: () => import('@/views/family/FamilyDetail.vue'), meta: { auth: true, title: '누리알리미' }, },
        { path: '/guardianEdit', name: 'guardianEdit', component: () => import('@/views/family/GuardianEdit.vue'), meta: { auth: true, title: '누리알리미' }, },
        { path: '/familyEdit', name: 'familyEdit', component: () => import('@/views/family/FamilyEdit.vue'), meta: { auth: true, title: '누리알리미' }, },
        { path: '/guardianAdd', name: 'guardianAdd', component: () => import('@/views/family/GuardianAdd.vue'), meta: { auth: true, title: '누리알리미' }, },
        { path: '/addGrdComplete/:resultCode', name: 'addGrdComplete', component: () => import('@/views/family/AddGrdComplete.vue'), meta: { title: '누리알리미' }, },
        { path: '/closeMessage', name: 'closeMessage', component: () => import('@/views/family/CloseMessage.vue'), meta: { title: '누리알리미' }, },
        // { path: '/family', name: 'family', component: () => import('@/components/family/Family.vue'), meta: { auth: true, title: '누리알리미' }, },

        // 09 : map 폴더
        // { path: '/mapAlarm', name: 'mapAlarm', component: () => import('@/components/map/MapAlarm.vue'), meta: { auth: true, title: '누리알리미' }, },
        // { path: '/map', name: 'map', component: () => import('@/components/map/Map.vue'), meta: { auth: true, title: '누리알리미' }, },

        // 10 : terms 폴더
        { path: '/termsCollection', name: 'termsCollection', component: () => import('@/views/terms/Collection.vue'), meta: { title: '누리알리미' }, },
        { path: '/termsService', name: 'termsService', component: () => import('@/views/terms/Service.vue'), meta: { title: '누리알리미' }, },
        { path: '/termsPersonalInfo', name: 'termsPersonalInfo', component: () => import('@/views/terms/PersonalInfo.vue'), meta: { title: '누리알리미' }, },
        { path: '/termsPrivacy', name: 'termsPrivacy', component: () => import('@/views/terms/Privacy.vue'), meta: { title: '누리알리미' }, },
        
        // 없는 페이지
        { path: '*', component: () => import('@/components/common/NotFoundPage.vue'), meta: { title: '누리알리미' }, }
    ]
});

/*
router.beforeEach((to, from, next) => {
    if (to.meta.auth && !store.getters.isLogin) {
      console.log('인증이 필요합니다');
      // if (window.ReactNativeWebView) {
      //   window.ReactNativeWebView.postMessage(
      //     JSON.stringify({ type: 'expiration' })
      //   );
      // }
      // next('/login');
      return;
    }
    next();
  });
*/

  router.afterEach((to, from) => {
    //nextTick은 DOM이 업데이트 된 후 실행됩니다.
    Vue.nextTick(() => {
      document.title = to.meta.title;
    });
  });
  
  export default router;