import axios from 'axios';
import { setInterceptors } from './common/interceptors';

// 액시오스 초기화 함수
function createInstance() {
  return axios.create({
    baseURL: process.env.VUE_APP_API_URL,
  });
}

// 액시오스 초기화 함수
function createInstanceWithAuth(url) {
  const instance = axios.create({
    baseURL: `${process.env.VUE_APP_API_URL}${url}`,
  });
  return setInterceptors(instance);
}

export const instance = createInstance();
export const service = createInstanceWithAuth('service');
export const setting = createInstanceWithAuth('setting');
export const home = createInstanceWithAuth('home');
export const notice = createInstanceWithAuth('notice');
export const complaint = createInstanceWithAuth('complaint');
export const survey = createInstanceWithAuth('survey');