import {
    saveAccessTokenToCookie,
    saveRefreshTokenToCookie,
    saveUsrIdToCookie,
    saveEmailToCookie,
    getAccessTokenFromCookie,
    getRefreshTokenFromCookie,
    getUsrIdFromCookie,
    getEmailFromCookie,
} from '@/utils/cookies';
import { registerUser, loginUser } from '@/api/auth';
import store from '../index'

const state = {
    accessToken: getAccessTokenFromCookie() || '',
    refreshToken: getRefreshTokenFromCookie() || '',
    usrId: getUsrIdFromCookie() || '',
    email: getEmailFromCookie() || '',
};

const getters = {
    isLogin(state) {
        return state.email !== '';
    },
    getUsrId() {
        return state.usrId;
    }
};

const mutations = {
    setAccessToken(state, token) {
        state.accessToken = token;
    },
    setRefreshToken(state, token) {
        state.refreshToken = token;
    },
    setUsrId(state, usrId) {
        state.usrId = usrId;
    },
    setEmail(state, email) {
        state.email = email;
    },
    clearAccessToken(state) {
        state.accessToken = '';
    },
    clearRefreshToken(state) {
        state.refreshToken = '';
    },
    clearUsrId(state) {
        state.usrId = '';
    },
    clearEmail(state) {
        state.email = '';
    },
};

const actions = {
    async SIGNUP({ commit }, userData) {
        const { data } = await registerUser(userData);
    },
    async LOGIN(commit, userData) {
        const response = await loginUser(userData);
        store.commit('setAccessToken', response.data.accessToken);
        store.commit('setRefreshToken', response.data.refreshToken);
        store.commit('setUsrId', response.data.usrId);
        store.commit('setEmail', response.data.email);
        saveAccessTokenToCookie(response.data.accessToken);
        saveRefreshTokenToCookie(response.data.refreshToken);
        saveUsrIdToCookie(response.data.usrId);
        saveEmailToCookie(response.data.email);
        return response.data;
    },
};

export default {
	state,
	getters,
	mutations,
    actions
};