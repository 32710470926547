function saveAccessTokenToCookie(value) {
  const exdate = new Date();
  // const miuntes = 30;
  const miuntes = 52560000;
  exdate.setMinutes(exdate.getMinutes() + miuntes);
  const expires = exdate.toUTCString();

  document.cookie = `accessToken=${value};expires=${expires}`;
}

function saveRefreshTokenToCookie(value) {
  const exdate = new Date();
  // const miuntes = 30;
  const miuntes = 52560000;
  exdate.setMinutes(exdate.getMinutes() + miuntes);
  const expires = exdate.toUTCString();

  document.cookie = `refreshToken=${value};expires=${expires}`;
}

function getAccessTokenFromCookie() {
  return document.cookie.replace(
    /(?:(?:^|.*;\s*)accessToken\s*=\s*([^;]*).*$)|^.*$/,
    '$1',
  );
}

function getRefreshTokenFromCookie() {
  return document.cookie.replace(
    /(?:(?:^|.*;\s*)refreshToken\s*=\s*([^;]*).*$)|^.*$/,
    '$1',
  );
}

function saveUsrIdToCookie(value) {
  const exdate = new Date();
  // const miuntes = 30;
  const miuntes = 52560000;
  exdate.setMinutes(exdate.getMinutes() + miuntes);
  const expires = exdate.toUTCString();

  document.cookie = `usrId=${value};expires=${expires}`;
}

function saveEmailToCookie(value) {
  const exdate = new Date();
  // const miuntes = 30;
  const miuntes = 52560000;
  exdate.setMinutes(exdate.getMinutes() + miuntes);
  const expires = exdate.toUTCString();

  document.cookie = `email=${value};expires=${expires}`;
}

function getUsrIdFromCookie() {
  return document.cookie.replace(
    /(?:(?:^|.*;\s*)usrId\s*=\s*([^;]*).*$)|^.*$/,
    '$1',
  );
}

function getEmailFromCookie() {
  return document.cookie.replace(
    /(?:(?:^|.*;\s*)email\s*=\s*([^;]*).*$)|^.*$/,
    '$1',
  );
}

function deleteCookie(value) {
  document.cookie = `${value}=; expires=Thu, 01 Jan 1970 00:00:01 GMT;`;
}

export {
  saveAccessTokenToCookie,
  saveRefreshTokenToCookie,
  getAccessTokenFromCookie,
  getRefreshTokenFromCookie,
  saveUsrIdToCookie,
  saveEmailToCookie,
  getUsrIdFromCookie,
  getEmailFromCookie,
  deleteCookie,
};
